import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Welcome on <code>clear-cache.com</code>.
        </p>
        <p>Here you will find information on how to clear your cache<br></br> and more interesting tech tipps soon.</p>
        <a
          className="App-link"
          href="https://clear-cache.com"
          target="_self"
          rel="noopener noreferrer"
        >
          Stay Tuned.
        </a>
      </header>
    </div>
  );
}

export default App;
